import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {

}
const config = {
  load: async function () {
    return (await hillo.get('/Datev.php?op=getAllDatevCashAccount', {})).content.filter(it => !it.deletedAt)
  },
}

export default IKDataEntity.ModelFactory(entity, config)
