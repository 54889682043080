import i18n from '@/i18n'

export default {
  getList: function () {
    return [
      {
        id: 1,
        name: i18n.t('OperatingIncome'),
      }, {
        id: 2,
        name: i18n.t('NonOperatingIncome'),
      },
    ]
  },
}
