import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
// import PaymentColors from '@/model/common/PaymentColors'
import InComeType from '@/model/bills/cashbook/InComeType'
import AccountStatus from '@/model/bills/cashbook/AccountStatus'
import { manageCashAccount } from '@/common/Utlis/api'
import i18n from '@/i18n'

const entity = {

  orderId: {
    displayName: 'orderNumber', // 订单编号
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredEdit: false,
      requiredNew: false,
    },
    form: false,
  },

  cashInCome: {
    displayName: 'TypeOfIncome',
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: InComeType.getList,
      },
      editable: false,
    },
    form: false,
  },

  name: {
    displayName: 'detail',
    form: false,
  },

  cashAccountNote: {
    displayName: 'note',

  },

  payLogAmount: {
    type: IKDataEntity.Types.Float,
    displayName: 'cash_amount',
    formConfig: {
      default: '',
      overwriteRule: true,
      rule: [
        v => /^[0-9]+\.{0,1}[0-9]{0,2}$/.test(v) || i18n.t('pls_enter_the_correct_price'),
      ],
    },
  },
  cashType: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      default: 1,
      type: {
        selectItems: async () => await AccountStatus.getList(true),
        itemText: 'name',
      },
      editable: false,
      merge: false,
      header: false,
    },
    header: false,
  },
  updateTimestamp: {
    type: IKDataEntity.Types.Date,
    displayName: 'updated_at',
    form: false,
  },

}
const config = {
  load: async function (filter) {
    const [fromDate, toDate] = filter[0].dateFilter
    const res = (await hillo.get('Complex.php?op=getCashInOutDetail', {
      fromDate,
      toDate,
    })).content.map(it => {
      if (it.cashAccountNote.toLowerCase() === 'umsatz' || it.cashAccountNote.toLowerCase() === 'trinkgeld') {
        it.cashInCome = 1
      } else {
        it.cashInCome = 2
      }
      if (!it.name) {
        if (it.payLogAmount >= 0) {
          it.name = i18n.t('Revenue')
        } else {
          it.name = i18n.t('Expenditure')
        }
      }
      return it
    })
    return res
  },
  add: async function (item) {
    const res = (await AccountStatus.getList()).find(it => it.id === item.cashType)
    item.cashUseType = res.lrMark
    return await manageCashAccount(item.cashUseType !== '1' ? -item.payLogAmount : item.payLogAmount, item.cashAccountNote,
      item.cashType)
  },

}

export default IKDataEntity.ModelFactory(entity, config)
